import React, { useEffect, useState } from 'react';
import { Container, Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';

const ProjectFiles = ({ user }) => {
    const [projectFiles, setProjectFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [showModalUser, setShowModalUser] = useState(false);
    const [activeProjectFiles, setActiveProjectFiles] = useState([]);

    const [modalProjectId, setModalProjectId] = useState(null);
    const [modalFileUrl, setModalFileUrl] = useState('');
    const [modalSubscriptionStatus, setModalSubscriptionStatus] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalMessageUser, setModalMessageUser] = useState('');
    const [appId, setAppId] = useState('');

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const PAGE_SIZE = 10;


    const [searchTerm, setSearchTerm] = useState("");

    const filteredProjects = projectFiles.filter((project) => {
        const id = project.id || ""; 
        const name = project.name || ""; 
        const email = project.useremail || ""; 
      
        return (
          id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });


    useEffect(() => {
        fetchProjectFiles();
    }, []);

    const fetchProjectFiles = async () => {
        try {
            const response = await fetch('https://api.streampixel.io/pixelStripeApi/projects');
            if (response.ok) {
                const data = await response.json();
    
                const filesArray = await Promise.all(data.map(async (project) => {
                    const userResponse = await fetch(`https://api.streampixel.io/pixelStripeApi/users/${project.user}`);
                    let userData = {};
                    if (userResponse.ok) {
                        userData = await userResponse.json();
                    }
    
                    const filteredFiles = project.files.filter(file => 
                        !['Approved', 'Ready', 'Reject'].includes(file.status)
                    );
    
                    return filteredFiles.map(file => ({
                        id: project._id,
                        name: project.name, 
                        username: userData?.name,
                        useremail: userData?.email,
                        userId: userData?._id,
                        fileUrl: file.url,
                        status: file.status,
                        subscriptionStatus: project.subscriptionStatus,
                        date:file.date
                    }));
                }));
    
                const allFiles = filesArray.flat();
    
                const sortFiles =  allFiles.sort((a, b) => new Date(b.date) - new Date(a.date));

                 
                setProjectFiles(sortFiles); 
            } else {
                console.error('Failed to fetch projects data');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };
    
    

    // Paginated data
    const paginatedFiles = filteredProjects.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE
    );

    // Handle page change
    const handleNextPage = () => {
        if (currentPage * PAGE_SIZE < filteredProjects.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const getCurrentLiveBuildUrl = (files) => {
        const activeFiles = files.filter(file => file.status === 'Approved');
        if (activeFiles.length === 0) return '';
        activeFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
        return activeFiles[0].url;
    };

    const getBuildInReviewUrl = (files) => {
        const pendingFiles = files.filter(file => file.status === 'pending');
        if (pendingFiles.length === 0) return '';
        pendingFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
        return pendingFiles[0].url;
    };

    const handleApprove = (projectId, files, fileUrl, subscriptionStatus, appName) => {

        console.log(files);
        setModalProjectId(projectId);
        setModalFileUrl(fileUrl);
        setModalSubscriptionStatus(subscriptionStatus);
        setAppId(appName);
        setActiveProjectFiles(files);
        setShowModalApprove(true);
    };


    const retryFileUpload = async(projectId, fileUrl, userId)=>{

        console.log(projectId);
        console.log(fileUrl);
        console.log(userId);


        try {
            
            const responseProject =  await axios.put(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`, 
              { fileUrl: fileUrl,
                webIp:process.env.REACT_APP_WEBIP,
               userId: userId });
    
    
               if (responseProject.status === 200) {
                console.log('Project status updated successfully');
                fetchProjectFiles();
                alert("Retry successfully");
            }
        } catch (error) {
            console.log(error);
        }
 
    }

    const handleApproveModalSubmit = async () => {
        try {
            let startDate, endDate;
    
            console.log(activeProjectFiles);
            // Check condition and set dates if applicable
            if (modalSubscriptionStatus === "authenticated" && activeProjectFiles.length === 1) {
                const currentDate = new Date();
                
                startDate = currentDate.toISOString(); 
                currentDate.setDate(currentDate.getDate() + 2); 
                endDate = currentDate.toISOString(); 

                console.log(endDate);
            }
    
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${modalProjectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileStatus: 'Approved',
                    fileUrl: modalFileUrl,
                    status: true,
                    appName: appId,
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP,
                    // Conditionally include the start and end dates
                    ...(startDate && endDate && {
                        subscriptionStartDate: startDate,
                        subscriptionEndDate: endDate
                    })
                })
            });
    
            if (response.ok) {
                console.log('Project status updated successfully');
                fetchProjectFiles(); // Refresh the project list after update
            } else {
                console.error('Failed to update project status', response.statusText);
            }
        } catch (error) {
            console.error('Error updating project status:', error);
        }
    
        setShowModalApprove(false);
        setAppId('');
    };
    
    const handleObjection = (projectId, fileUrl) => {
        setModalProjectId(projectId);
        setModalFileUrl(fileUrl);
        setShowModal(true);
    };

    const handleModalSubmit = async () => {
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${modalProjectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileStatus: 'Reject',
                    fileUrl: modalFileUrl,
                    userId: user._id,
                    fileobjection: modalMessage,
                    webIp: process.env.REACT_APP_WEBIP
                })
            });

            if (response.ok) {
                console.log('Project status updated successfully');
                fetchProjectFiles(); // Refresh the project list after update
            } else {
                console.error('Failed to update project status', response.statusText);
            }
        } catch (error) {
            console.error('Error updating project status:', error);
        }

        setShowModal(false);
        setModalMessage('');
    };

    const handleUserUpdate = (projectId, noOfUser) => {
        setModalProjectId(projectId);
        setModalMessageUser(noOfUser);
        setShowModalUser(true);
    };

    const handleModalUserSubmit = async () => {
        try {
            const response = await fetch(`https://api.streampixel.io/pixelStripeApi/projects/${modalProjectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    allowedUsers: modalMessageUser,
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP
                })
            });

            if (response.ok) {
                console.log('Project status updated successfully');
                fetchProjectFiles(); // Refresh the project list after update
            } else {
                console.error('Failed to update project status', response.statusText);
            }
        } catch (error) {
            console.error('Error updating project status:', error);
        }

        setShowModalUser(false);
        setModalMessageUser('');
    };

    return (
        <Container>
            <Row>
                <Col xs={2}>
                    <h4>Project Files</h4>
                </Col>

                <Col xs = {4}>
      <input
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          marginBottom: "10px",
          padding: "8px",
          width: "300px",
          fontSize: "16px",
        }}
      />
                </Col>

                <Col md={{ span: 2, offset: 4 }}>
                    <div style={{ textAlign: 'end' }}>
                        <Button onClick={fetchProjectFiles} size="sm">Refresh Data</Button>
                    </div>
                </Col>
            </Row>

            <Table striped bordered hover style={{ tableLayout: 'fixed' }}>
    <thead>
        <tr>
            <th style={{ width: "100px" }}>Project Id</th>
            <th style={{ width: "100px" }}>User Id</th>
            <th style={{ width: "150px" }}>Name</th>
            <th style={{ width: "150px" }}>Use Name</th>
            <th style={{ width: "200px" }}>Use Email</th>
            <th style={{ width: "150px" }}>Subscription Status</th>
            <th style={{ width: "100px" }}>File Url</th>
            <th style={{ width: "100px" }}>File Status</th>
            <th style={{ width: "100px" }}>Date</th>
            <th style={{ width: "100px" }}>Action</th>
        </tr>
    </thead>
    <tbody>
        {paginatedFiles.map(project => (
            <tr key={project.id}>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.id}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.userId}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.name}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.username}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.useremail}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.subscriptionStatus}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.fileUrl}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{project.status}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{moment(project.date).format("DD/MM/YYYY")}</td>
                <td style={{ wordWrap: 'break-word', overflow: 'hidden' }}><button onClick={()=>retryFileUpload(project.id,project.fileUrl,project.userId)}>Retry Upload</button></td>
            </tr>
        ))}
    </tbody>
</Table>


            <div style={{ textAlign: 'center' }}>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button onClick={handleNextPage} disabled={currentPage * PAGE_SIZE >= projectFiles.length}>
                    Next
                </Button>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Raise Objection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formObjectionMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                value={modalMessage}
                                onChange={(e) => setModalMessage(e.target.value)} />
                        </Form.Group>
                        <br />
                        <Button variant="primary" onClick={handleModalSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showModalApprove} onHide={() => setShowModalApprove(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formObjectionMessage">
                            <Form.Label>App Path</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                value={appId}
                                onChange={(e) => setAppId(e.target.value)} />
                        </Form.Group>
                        <br />
                        <Button variant="primary" onClick={handleApproveModalSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showModalUser} onHide={() => setShowModalUser(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update No Of users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formObjectionMessage">
                            <Form.Control rows={3}
                                value={modalMessageUser}
                                onChange={(e) => setModalMessageUser(e.target.value)} />
                        </Form.Group>
                        <br />
                        <Button variant="primary" onClick={handleModalUserSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default ProjectFiles;
