import React, { useState,useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import mixpanel from '../mixpanel';


const CreateProject = ({ user,userCountry }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [projectId, setProjectId] = useState('');
    const [subId, setSubId] = useState('');

    const navigate = useNavigate();
    const rzpRef = useRef();

    const handleChange = (e) => {
        setName(e.target.value);
    };


    

    const handleSubmit = async (e) => {



        e.preventDefault();
        try {

            setLoading(true); // Start loading indicator
            const today = moment().add(5, 'minutes');
            const unixEndDate = today.unix();

            
            const response = await axios.post('https://api.streampixel.io/pixelStripeApi/projects', {
                name,
                status: false,
                user: user._id,
                allowedUsers: 2,
                isFreeTrial:false,
                isLocal:false,
                subStatus: "created",
                subscriptionStartDate: unixEndDate,
                webIp: process.env.REACT_APP_WEBIP
            });

            const projectId = response.data.projectId;

            setUserName(response.data.name);
            setEmail(response.data.email);
            setContact(response.data.contact);
            setProjectId(response.data.projectId);
            setSubId(response.data.subId);
            
    mixpanel.track('Project Created', {
        "UserId": user._id,
        "Project Id":response.data.projectId,
        "Project Name":name
      });
      navigate(`/projectDetail/${projectId}`);


        } catch (error) {
            console.error('Error creating project:', error);
        } finally {
            setLoading(false); 
        }
    };

    const handleBackClick = () => {
        navigate('/dashboard');
    };

    return (
        <div>
{loading && (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <p style={{ marginTop: '10px' }}>Please wait...</p>
    </div>
)}

            {!loading && (
                <>
                 <br/>
                 <Button size='sm' variant="primary" onClick={handleBackClick}>
                        <FaArrowLeft /> Back 
                    </Button>
                    <br/>
                    <br/>
                    <h5>Create a Project:</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Project Name</label>
                            <input type="text" className="form-control" id="name" value={name} onChange={handleChange} required />
                        </div>
                        <Button type="submit" className="btn btn-primary">Continue</Button>
                    </form>
                </>
            )}
        </div>
    );
};

export default CreateProject;
