import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body';
import GoogleLoginComponent from './components/GoogleLogin';
import UpdateAccountDetails from './components/UpdateAccountDetails';
import CreateProject from './components/createProject';
import ProjectDetail from './components/ProjectDetail';
import Dashboard from './components/Dashboard';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import CreateProjectTrial from './components/CreateProjectTrial';
import Admin from './components/Admin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import IntercomConnect from './components/IntercomConnect';
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const paypalId =  process.env.REACT_APP_PAYPAL_CLIENT_KEY;
const App = () => {
    const [user, setUser] = useState(null);
    const [userCountry, setUserCountry] = useState("EU");
    const [loading, setLoading] = useState(true); 


    useEffect(() => {
        
        handleLogin(); 
    }, [user]);

    const handleLogin = async () => {
        const userId = localStorage.getItem('userId');
      

        try {
            const res = await axios.get(`https://api.streampixel.io/pixelStripeApi/users/${userId}`);
            setUser(res.data);
   

        } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error: You can show a message to the user or retry the request
        } finally {
            setLoading(false); // Set loading to false when data fetching is complete
        }
    };

    if (loading) {
        // Show loading indicator until user data is fetched
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <GoogleOAuthProvider clientId={clientId}>
            <Container fluid >
                <Header user={user} setUser={setUser} />
            
                <Body >

                <PayPalScriptProvider options={{ "client-id": paypalId, vault: true, currency: "USD" }}>
                    {user !== null &&
                <IntercomConnect user={user}/>
                    }

    <Routes>

        <Route exact path="/" element={<GoogleLoginComponent onLogin={setUser} />} />
        <Route path="/account" element={user ? <UpdateAccountDetails user={user} setUser={setUser} /> : <Navigate to="/" />} />
        <Route path="/dashboard" element={user ? <Dashboard  user={user} setUser={setUser} userCountry={userCountry}/> : <Navigate to="/" />} />
        <Route path="/createProject" element={user ? <CreateProject user={user} userCountry={userCountry}/> : <Navigate to="/" />} />
        <Route path="/createProjectTrial" element={user ? <CreateProjectTrial user={user} userCountry={userCountry}/> : <Navigate to="/" />} />
        <Route path="/admin" element={user ? <Admin user={user} setUser={setUser} /> : <Navigate to="/" />} />
        <Route path="/projectDetail/:projectId" element={user ? <ProjectDetail user={user} userCountry={userCountry}/> : <Navigate to="/" />} />   
    </Routes>
</PayPalScriptProvider>

                   
                </Body>
                <Footer />
            </Container>
            </GoogleOAuthProvider>
        </Router>
    );
};

export default App;
