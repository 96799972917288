import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import moment from 'moment';

export default function IntercomConnect({ user }) {

  useEffect(() => {
    if (user && user._id) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_ID,  
        user_id: user._id,
        name: user.name,
        email: user.email,
        created_at: moment().unix(), 
      });
    }
  }, [user]); 

  return <div></div>;  
}
