
import mixpanel from 'mixpanel-browser';


mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    api_host: 'https://mixapi.streampixel.io' ,
    record_mask_text_class: null,
    record_mask_text_selector:null,	
    debug: true,    
});




export default mixpanel;